<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/assets/scss/index.scss";
@import "@/assets/css/flex.css";
.content {
  width: $base-menu-width;
  height: $base-menu-width;
}
</style>
