// 对外暴露配置路由（常量路由）
export const constanRouter = [
  {
    //路由路径
    path: "/login",
    // @ts-ignore
    //路由组件
    component: () => import("@/views/login/index.vue"),
    name: "login", //命名路由
    meta: {
      title: "登录", //菜单标题
      hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
    },
  },
  // {
  //   path: "/",
  //   component: () => import("@/layout/index.vue"),
  //   name: "layout", //命名路由
  //   redirect: "/home", //重定向
  //   children: [
  //     {
  //       path: "/home",
  //       component: () => import("@/views/home/index.vue"),
  //       meta: {
  //         title: "首页",
  //         icon: "el-icon-s-home", //菜单左侧的图标
  //       },
  //     },
  //   ],
  // },
  // {
  //   path: "/screen",
  //   component: () => import("@/views/screen/index.vue"),
  //   name: "screen", //命名路由
  //   meta: {
  //     title: "数据大屏",
  //     icon: "el-icon-monitor",
  //   },
  // },
  {
    path: "/",
    component: () => import("@/layout/index.vue"),
    name: "district", //命名路由
    redirect: "/district/index",
    meta: {
      title: "医院管理",
      icon: "el-icon-school",
    },
    children: [
      {
        path: "/district/index",
        component: () => import("@/views/district/index.vue"),
        meta: {
          title: "院区列表",
        },
      },
      {
        path: "/departments/index",
        component: () => import("@/views/departments/index.vue"),
        meta: {
          title: "科室列表",
        },
      },
    ],
  },
  {
    path: "/doctor",
    component: () => import("@/layout/index.vue"),
    name: "doctor", //命名路由
    redirect: "/doctor/index",
    meta: {
      title: "医生管理",
      icon: "el-icon-user-solid",
    },
    children: [
      {
        path: "/doctor/index",
        component: () => import("@/views/doctor/index.vue"),
        meta: {
          title: "医生列表",
        },
      },
    ],
  },
  {
    path: "/doctorDuty",
    component: () => import("@/layout/index.vue"),
    name: "doctorDuty", //命名路由
    redirect: "/doctorDuty/index",
    meta: {
      title: "排班管理",
      icon: "el-icon-date",
    },
    children: [
      {
        path: "/doctorDuty/index",
        component: () => import("@/views/doctorDuty/index.vue"),
        meta: {
          title: "排班列表",
        },
      },
    ],
  },
  {
    path: "/order",
    component: () => import("@/layout/index.vue"),
    name: "order", //命名路由
    redirect: "/order/index",
    meta: {
      title: "订单管理",
      icon: "el-icon-document",
    },
    children: [
      {
        path: "/order/index",
        component: () => import("@/views/order/index.vue"),
        meta: {
          title: "订单列表",
        },
      },
    ],
  },
  {
    path: "/user",
    component: () => import("@/layout/index.vue"),
    name: "user", //命名路由
    redirect: "/user/index",
    meta: {
      title: "用户管理",
      icon: "el-icon-user",
    },
    children: [
      {
        path: "/user/index",
        component: () => import("@/views/user/index.vue"),
        meta: {
          title: "用户列表",
        },
      },
    ],
  },
  {
    path: "/system",
    component: () => import("@/layout/index.vue"),
    name: "system", //命名路由
    redirect: "/system/banner",
    meta: {
      title: "系统管理",
      icon: "el-icon-setting",
    },
    children: [
      {
        path: "/system/banner",
        component: () => import("@/views/system/banner/index.vue"),
        meta: {
          title: "轮播图",
        },
      },
      {
        path: "/system/menu",
        component: () => import("@/views/system/menu/index.vue"),
        meta: {
          title: "金刚区",
        },
      },
    ],
  },
  {
    //路由路径
    path: "/404",
    //路由组件
    component: () => import("@/views/404/index.vue"),
    name: "404", //命名路由
    meta: {
      title: "404",
      hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
    },
  },
  {
    //路由路径
    path: "/:pathMatch(.*)*",
    //重定向到404路由
    redirect: "/404",
    name: "Any",
    meta: {
      title: "任意路由",
      hidden: true, //代表路由标题在菜单中是否隐藏， true：隐藏，false：不隐藏
    },
  },
];
